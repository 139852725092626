.prompt-add-btn {
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 1rem;
  gap: 0.5rem;
  background-color: black;
  color: #fff;
}

.prompt {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0.25rem 1rem;
  border: 1px solid #474a4c;
  height: 35px;
}

/* ExampleComponent.css (or use inline styles in React if preferred) */
.example-item {
  margin-bottom: 10px;
}

.overflow-div {
  overflow: auto; /* Adiciona scrollbars apenas se necessário */
  flex-grow: 1; /* Ajusta para preencher a altura restante */
  max-height: calc(
    100vh - 150px
  ); /* Define a altura máxima com base na altura da tela */
}

.highlight {
  position: relative;
  padding-left: 20px; /* Adjust for space for circle */
}

.highlight::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 12px; /* Adjust circle size */
  height: 12px;
  background-color: #05a359; /* Adjust circle color */
  border-radius: 50%;
}

.card {
  margin-top: 20px;
  border: 4px solid #ccc;
  border-radius: 8px;
}

.monaco-editor .mtk2 {
  /* mtk2 é a classe para comentários no Monaco Editor */
  color: #00ff00 !important; /* Define a cor verde para os comentários */
}

.card:hover {
  margin-top: 20px;
  border: 4px solid #00308f;
  border-radius: 8px;
}

.card-body {
  padding: 20px;
  border: 4px solid #ccc;
  border-radius: 8px;
}

.card-body:hover {
  border: 4px solid #00308f;
}

.card-title {
  font-size: 1.25rem;
  margin-bottom: 15px;
}

.cardVocabulary {
  margin-top: 20px;
  border: 3px solid #ccc;
  border-radius: 8px;
}

.cardVocabulary-body {
  padding: 20px;
  border: 3px solid #ccc;
  border-radius: 8px;
}

.cardVocabulary-body:hover {
  border: 3px solid #aa0000;
}

.cardVocabulary:hover {
  border: 3px solid #aa0000;
}

.cardVocabulary-title {
  font-size: 1.25rem;
  margin-bottom: 15px;
}
